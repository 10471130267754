/* ------ */
/* HEADER */
/* ------ */

$(function () {
    onScroll();

    // Éxécution au resize temps réel.
    $(window).on('resize', function () {
        onScroll();
    });

    /**
     * Ajout d'un marginTop au <main>,
     * pour éviter saccades lorsque <header> devient fixe.
     */
    function onScroll() {
        if ($('body > div.banniere').length) {
            const banniere = $('body > div.banniere');
            const topPosBanniere = $(banniere).position().top;
            $(window).on('scroll', function () {
                const posScroll = $(document).scrollTop();
                if (posScroll >= 1) {
                    $('body > header > nav.navbar').addClass("fixed-top");
                    $(banniere).css({
                        'marginTop': topPosBanniere,
                    });
                } else {
                    $('body > header > nav.navbar').removeClass("fixed-top");
                    $(banniere).css({
                        'marginTop': "0",
                    });
                }
            });
        } else {
            const main = $('body > main.container');
            const topPosMain = $(main).position().top;
            $(window).on('scroll', function () {
                const posScroll = $(document).scrollTop();
                if (posScroll >= 1) {
                    $('body > header > nav.navbar').addClass("fixed-top");
                    $(main).css({
                        'marginTop': topPosMain,
                    });
                } else {
                    $('body > header > nav.navbar').removeClass("fixed-top");
                    $(main).css({
                        'marginTop': "0",
                    });
                }
            });
        }
    }
});
